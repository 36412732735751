
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
    TmChip,
    TmTabs,
    TmTooltip,
    DetailsTable,
    DetailsTableItem,
  },
  setup() {
    const tab = ref('html')
    const tabs = ref([
      { name: 'html', label: 'HTML' },
      { name: 'text', label: 'Text' },
      { name: 'source', label: 'Source' },
    ])

    const isCopied = ref(false)
    const onCopy = () => {
      isCopied.value = true

      setTimeout(() => {
        isCopied.value = false
      }, 5000)
    }

    return {
      tab,
      tabs,
      isCopied,
      onCopy,
    }
  },
})
